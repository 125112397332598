[role='Fieldset'] {
  margin-bottom: 0.5rem;
}

/* CSS rule to set top margin to 3 for elements with the role 'Fieldset' that are not the first child */
[role='Fieldset']:not(:first-child) {
  margin-top: 1rem;
}

/* CSS rule to set top margin to 0 for the first child element with the role 'Fieldset' */
[role='Fieldset']:first-child {
  margin-top: 0rem;
}

/* CSS rule to set top margin to 3 for elements with the role 'Fieldset' if their parent is a flex-row and is not the first child */
.row:not(:first-child) > [role='Fieldset'],
.flex-row:not(:first-child) > [role='Fieldset'] {
  margin-top: 1rem;
}

/* CSS rule to set top margin to 0 for elements with the role 'Fieldset' if their parent is the first child of a flex-row */
.row:first-child > [role='Fieldset'],
.flex-row:first-child > [role='Fieldset'] {
  margin-top: 0rem;
}

[role='Fieldset__startDivider'] {
  margin-top: 0rem !important;
  margin-bottom: 1rem !important;
}

[role='Fieldset__endDivider'] {
  margin-top: 0.5rem !important;
  margin-bottom: 0rem !important;
}

[role='PageContent'] > :first-child {
  height: var(--paperHeight);
}
