
.icon-edit {
  cursor:pointer;
}

.cand-match-click {
  text-decoration: underline;
  cursor: pointer;
}
.candidate-skills{
  text-align: center;
  padding: 0px 10px 15px;
}

.fc-button-remove:before {
  margin: 3px;
  width: 30px;
  height: 30px;
  content: '\00d7';
  font-size: 26px;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  color: red;
  cursor: pointer;
}

.fc-button-edit:before {
  margin: 3px;
  width: 30px;
  height: 30px;
  content: '\270E';
  font-size: 26px;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  color: blue;
  cursor: pointer;
}

.fc-group-button-icon {
  opacity: .6;
  font-weight: 100;
}

.fc-button-color-secondary {
  color: blue;
  cursor: pointer;
}

.fc-button-color-view {
  color: #454545;
  cursor: pointer;
}

.fc-dropdownmenu-contextmenu-item {
  padding: 5px;
  cursor: pointer;
}

.fc-dropdownmenu-contextmenu {
  position: fixed;
  background-color: white;
  z-index: 20002;
  padding: 5px;
  box-shadow: 0 1px 10px #0005;
}

.fc-dropdown-position {
  position: absolute;
  margin-top: 27px;
}

.fc-value-editor {
  margin: 3px;
  padding: 0 3px;
}

.pad-btm-15 {
  margin-bottom: 15px !important;
}

.removeClass {
  opacity: 0;
}

.must-have-save {
  float: right;
  color: #00bfa5 !important; 
  background-color: white !important;
  border: 1px solid #00bfa5 !important;
}

.admin-add-alter {
  cursor: pointer;
  float: left;
  margin-left: 15px;
}
.admin-add-alter-title {
  margin-right: 570px;
  margin-top: 5px;
}

.tag-field-hover {
  z-index: 10;
  position: absolute;
  margin-top: -85px;
  width: 250px;
}
.hover-white-space {
  white-space:pre;
}
.button-rec-add {
  background-color: green !important;
  color:white !important;
}
.button-rec-delete {
  background-color: red !important;
  color:white !important;
}

.rec-actions {
  color: white;
  cursor: pointer;
  margin: 2px 2px;
}

.cross-icon-jt {
  top: 18px;
  position: relative;
}
