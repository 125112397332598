.stepper-control {
  position: relative;
}

.stepper-control .conector {
  height: 1.2px;
  background-color: var(--blackLighter);
  position: absolute;
  top: 24px;
  left: 48px;
}

.stepper-control .before,
.stepper-control .after {
  cursor: pointer;
}

.stepper-control .before,
.stepper-control .after,
.stepper-control .active {
  background: none;
  outline: none;
  display: inline-block;
  width: 120px;
  height: auto;
  text-align: center;
}

.stepper-control .step:hover .icon {
  background: var(--purpleDarker) !important;
  border: 1px solid var(--purpleDarker) !important;
  color: var(--whiteCommon) !important;
  font-weight: 700 !important;
}

.stepper-control .step:hover .title {
  color: var(--purpleDarker) !important;
}

.stepper-control .active * {
  color: var(--purpleCommon) !important;
}

.stepper-control .active .icon {
  background-color: var(--purpleCommon) !important;
  color: var(--whiteCommon) !important;
}

.stepper-control .before .icon {
  background-color: var(--cyanCommon) !important;
  border: 1px solid var(--cyanCommon) !important;
  color: var(--whiteCommon) !important;
}

.stepper-control .before .title {
  color: var(--cyanMedium) !important;
}

.stepper-control .icon {
  background: var(--whiteCommon);
  border: 1px solid var(--blackLight);
  color: var(--blackMedium);
  border-radius: 18px;
  width: 36px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  display: block;
  margin: auto;
  font-weight: 500;
  cursor: pointer;
}

.stepper-control .title {
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 33px;
  color: var(--blackLight);
}
