.rich-text-box.match-new .public-DraftEditor-content {
  min-height: fit-content;
}

.text-field.match-new {
  min-height: fit-content;
  /*max-height:15em;*/
}

.match-new {
  color: #454545;
}

.match-new a {
  text-decoration: none;
  cursor: pointer;
}

.match-new.candidate-name {
  margin-bottom: 7px;
  font-size: 16px;
}

.match-new.candidate-attributes p {
  margin: 3px 5px 3px 0px;
  font-size: 14px;
}

.match-new.candidate-attributes p:nth-last-child(1) {
  margin: 3px 5px 16px 0px;
}

.match-new.candidate-attributes p:first-child span {
  font-size: 12px;
  margin: 5px 5px 5px 0px;
}

.match-new.candidate-name span {
  font-size: 10px;
}

.match-new.recruiter-name {
  font-size: 14px !important;
}

.match-new.section-card {
  padding: 0px 0px;
  box-shadow: 0px 0px 10px #888888;
}

.match-new.match-new.job-list-card-header {
  margin: 0px;
  font-size: 14px;
  font-weight: bold;
  padding: 3px 10px 3px 10px;
}

.match-new.match-new.candidate-list-card-header {
  margin: 0px;
  font-size: 14px;
  padding: 3px 10px 3px 10px;
}

.match-new.job-list-card-content {
  padding: 1px 10px;
  color: #454545 !important;
  padding-bottom: 10px !important;
}

.match-new.card-content {
  padding: 1px 10px;
  box-shadow: 0px 0px 10px #888888;
}

.match-new.card-content p {
  margin: 8px 0px 4px 0px;
  font-size: 14px;
}

.match-new.section-header {
  font-size: 16px;
  margin: 1px 1px;
  text-align: center;
}

.match-new.heading-center {
  font-size: 16px;
  margin: 1px 1px;
  padding: 6px 0px 0px 0px;
}

.match-new.heading-center span {
  font-size: 14px;
}

.match-new .subm-write {
  padding: 0px;
  font-size: 14px;
}

.match-new.span-date {
  font-size: 10px;
  margin: 0px 1px;
}

.match-new.section-card hr {
  margin: 1px 0px;
}

.match-new .rich-text-box .public-DraftEditor-content {
  font-size: 14px;
}

.match-new div.text-edit {
  font-size: 14px !important;
}

.match-new.job-card-text {
  font-size: 13px !important;
  color: #454545 !important;
  line-height: 110% !important;
}

.match-new.job-list-card-content p {
  margin-top: 0px;
  margin-bottom: 3px;
}

.match-new.drawer div:nth-child(1) {
  width: 75%;
}

.match-new.drawer-text {
  font-size: 13px;
  margin: 5px 3px 5px 3px;
  padding: 0px 3px;
}
