.MuiAlert-icon {
  align-items: center;
}

.MuiAlert-icon svg Ø {
  font-size: 1.2rem !important;
}

.MuiChip-icon {
  font-size: 1rem !important;
}

.card-picker-date-tf .MuiInputBase-root-48 {
  width: 100%;
}

.MuiInput-underline-54:before {
  left: 0;
  right: 0;
  bottom: 0;
  content: '\00a0';
  position: absolute;
  transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-bottom: 1px solid rgb(224, 224, 224) !important;
  pointer-events: none;
}

.MuiInput-underline-54:after {
  left: 0;
  right: 0;
  bottom: 0;
  content: '\00a0';
  position: absolute;
  transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-bottom: 2px solid rgb(89, 115, 111) !important;
  pointer-events: none;
}

.MuiCardContent-root-181 {
  padding-bottom: 1px !important;
}

.list-card-section.disable + div > .MuiPaper-root-138 {
  background-color: #fff !important;
}
