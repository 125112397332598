.hover-border-purple:hover {
  border-color: var(--purpleCommon) !important;
}

.hover-visible {
  display: none !important;
}

.hover-border-purple:hover .hover-visible,
.hover-visible:hover {
  display: inline !important;
}
