:root {
  --outlined: 1px solid var(--blackLighter);
  --containerMinMargin: 2rem;
  --paperHeight: calc(100vh - 64px - 1rem);
  --matchPipeHeightV1: calc(100vh - 64px - 1rem - 80px);
  --matchListHeightV1: calc(100vh - 64px - 1rem - 80px - 120px);
  --matchPipeHeightV3: calc(100vh - 64px - 1rem - 232px);
  --matchListHeightV3: calc(100vh - 64px - 1rem - 232px - 120px);
}

* {
  box-sizing: border-box;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  /** 
    * @hack 
    * Enable it to debug pixels.
    * /
  outline: 1px dotted red;
  /* */
}

html,
body {
  overscroll-behavior-x: none;
}

body {
  background-color: var(--bgMainCommon) !important;
  margin: 0 !important;
  padding: 0 !important;
  color: var(--blackMedium);
  font-size: 0.775rem !important;
  font-weight: 500;
  overflow: hidden;
}

h5 {
  margin: 0 0 10px 0;
}

pre {
  white-space: pre-wrap;
}

input {
  outline: none !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
select:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 100px var(--whiteCommon) inset !important;
  -webkit-text-fill-color: var(--purpleCommon) !important;
  box-shadow: 0 0 0 100px var(--whiteCommon) inset !important;
  background-color: var(--purpleCommon) !important;
  background-image: none !important;
  color: rgb(0, 0, 0) !important;
}

.break-all,
.break-all * {
  word-break: break-all !important;
}

.auto-widths,
.auto-widths * {
  width: auto !important;
}

.hulk:hover {
  font-weight: 700 !important;
  cursor: default;
}

div.card-col-header {
  font-size: 14px !important;
}

.form-header > div {
  font-size: 18px !important;
}

.f-arial,
.f-arial * {
  font-family: Arial, Helvetica, sans-serif !important;
}

.f-italic {
  font-style: italic !important;
}

.f-normal {
  font-size: 1rem !important;
}

.f-1em {
  font-size: 1em !important;
}

.f-xxxl {
  font-size: 1.375rem !important;
}

.f-xxl {
  font-size: 1.25rem !important;
}

.f-xl {
  font-size: 1.125rem !important;
}

div.f-lg,
.f-md svg,
.f-lg {
  font-size: 1rem !important;
}

div.f-md,
.f-md {
  font-size: 0.875rem !important;
}

div.f-sm,
.f-sm {
  font-size: 0.75rem !important;
}

div.f-xs,
.f-xs {
  font-size: 0.625rem !important;
}

.f-11,
.f-11 * {
  font-size: 11px !important;
}

.f-12,
.f-12 * {
  font-size: 12px !important;
}

.f-13,
.f-13 * {
  font-size: 13px !important;
}

.f-14,
.f-14 * {
  font-size: 14px !important;
}

.fw-300 {
  font-weight: 300 !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.fw-normal {
  font-weight: normal !important;
}

.f-bold {
  font-weight: bold !important;
}

small,
.f-small,
.f-smalls *,
.form *.f-small,
span.show-more {
  font-size: 12px !important;
}

.f-normal,
.f-normal * {
  font-weight: normal !important;
}

.f-bold,
.f-bold * {
  font-weight: bold !important;
}

.filter-clear-all span,
.filter-save-search span,
.form-header > div:nth-child(n + 2),
.warning-text {
  font-size: 12px !important;
}

.f-big-all *,
.results {
  font-family: 'Courier New', Courier, monospace !important;
  font-size: 1rem !important;
}

.snack-bar span {
  font-size: 20px !important;
}

.form-header > div:nth-child(n + 2) {
  font-weight: 500 !important;
}

.green-block h4 {
  font-weight: 600;
  text-transform: uppercase;
}

div.stage-selected,
.form-header > div {
  font-weight: 600;
}

.row-card .details span {
  line-height: 32px;
}

.row-card .show-more > span {
  font-weight: 200;
  line-height: 24px;
  text-decoration: underline;
}

.list .group-header {
  font-weight: normal;
  text-transform: capitalize;
}

.warning-text,
.filter-control-search-input {
  line-height: 12px !important;
}

.list-item-checkbox,
.filter-control-search-input label {
  line-height: 14px !important;
}

.filter-control-item {
  line-height: 24px !important;
}

.collection-controls {
  font-weight: 400;
}

.truncate {
  /** to works it is needed define the width in your element */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.no-truncate {
  /** to works it is needed define the width in your element */
  white-space: unset !important;
  overflow: unset !important;
  text-overflow: unset !important;
}

.pass-through,
.no-pointer-events {
  pointer-events: none !important;
}

.nowrap-scroll-x {
  max-width: 100%;
  white-space: nowrap;
  overflow-x: auto;
}

/** BLUE */
.row-card .show-more > span,
.row-card .show-more > i,
.cblue,
.cblue b,
.cblue p,
.cblue li,
.cblue td,
.cblue div,
.cblue span,
.cblue pre,
.cblue i {
  color: #1675d7 !important;
}

/** PURPLE */
a,
.anchor,
.stage-selected .card-col-header,
.stage-selected .card-col-header i,
.row-card .first-item a > *,
.row-card .link-item a > *,
.filter-control-item,
.purples-over:hover *,
.ondev,
.ondev b,
.ondev p,
.ondev li,
.ondev td,
.ondev div,
.ondev span,
.ondev pre,
.ondev i {
  color: var(--purpleCommon) !important;
}

.purple-switch > div > div > div:nth-child(1) {
  background-color: #92a2fa !important;
}

.purple-switch > div > div > div:nth-child(2) {
  background-color: var(--purpleCommon) !important;
}

/** RED */
.row-card p.cred,
.row-card span.cred,
.cred,
.cred b,
.cred p,
.cred li,
.cred td,
.cred div,
.cred span,
.cred pre,
.cred i {
  color: rgb(234, 0, 0) !important;
}

/** CYAN */
.filter-save-search,
.filter-saved-searches,
.ccyan,
.ccyan b,
.ccyan p,
.ccyan li,
.ccyan td,
.ccyan div,
.ccyan span,
.ccyan pre,
.ccyan i {
  color: var(--cyanCommon) !important;
}

/** ORANGE */
.filter-clear-all,
.warning-text,
.corange,
.corange b,
.corange p,
.corange li,
.corange td,
.corange div,
.corange span,
.corange pre,
.corange i {
  color: orange !important;
}

/** YELLOW */
.cyellow,
.cyellow b,
.cyellow p,
.cyellow li,
.cyellow td,
.cyellow div,
.cyellow span,
.cyellow pre,
.cyellow i {
  color: yellow !important;
}

/** LIGHTER */
.results,
.list-match,
.list-add-new,
.profile-header,
.profile-header p,
.profile-header i,
.profile-header div,
.profile-header span,
.tags span,
.snack-bar span,
p.clighter,
.clighter,
.clighter b,
.clighter p,
.clighter li,
.clighter td,
.clighter div,
.clighter span,
.clighter pre,
.clighter i {
  color: rgb(255, 255, 255) !important;
}

p.transparent {
  color: transparent !important;
}

/** BACKGROUND, BORDER and other stuff COLORS */
.filter-control-search {
  background-color: white !important;
}

.ondev,
.ondev b,
.ondev p,
.ondev li,
.ondev td,
.ondev div,
.ondev span,
.ondev pre,
.ondev i {
  background-color: transparent !important;
}

.filter-control-search {
  border-color: #d6d6d6 !important;
}

.filter-control-item {
  border-right-color: #e0e0e0 !important;
}

.rich-text-box.disabled {
  border-color: rgba(0, 0, 0, 0.3) !important;
}

.bgcolor1 {
  background-color: #ffffff !important;
}

.bgcolor2,
.edit {
  background-color: var(--bgMainCommon) !important;
}

.bgcolorred {
  background-color: rgb(255, 236, 229) !important;
}

.bgcolorredYellow {
  background-color: lightgoldenrodyellow !important;
}

.edit-account-list-col {
  background-color: rgba(255, 255, 255, 0.84);
}

.list-match,
.list-add-new,
.bgcolor3,
.bgcolor3 button {
  background-color: var(--cyanCommon) !important;
}

.filter-save-search,
.filter-saved-searches {
  background-color: white !important;
  border: 1px solid var(--cyanCommon) !important;
}

.button-1 {
  background-color: var(--cyanCommon) !important;
  color: white !important;
}

button.button-flat-darker {
  background-color: transparent !important;
  border: 0px solid var(--blackDarker) !important;
  margin: 2px !important;
}

button.button-flat-darker * {
  color: var(--blackDarker) !important;
}

button.button-flat-cyan {
  background-color: transparent !important;
  border: 0px solid var(--cyanCommon) !important;
  margin: 2px !important;
}

button.button-flat-cyan * {
  color: var(--cyanCommon) !important;
}

button.button-cyan,
.button-cyan button {
  background-color: var(--cyanCommon) !important;
  border: 1px solid var(--cyanCommon) !important;
  margin: 2px !important;
}

button.button-cyan,
button.button-cyan *,
.button-cyan button * {
  color: white !important;
}

button.button-white-cyan,
.button-white-cyan button {
  background-color: white !important;
  border: 1px solid var(--cyanCommon) !important;
  margin: 2px !important;
}

button.button-white-cyan *,
.button-white-cyan button * {
  color: var(--cyanCommon) !important;
}

button.button-white-bordered,
.button-white-bordered button {
  background-color: transparent !important;
  border: 2px solid white !important;
  margin: 2px !important;
}

button.button-white-bordered *,
.button-white-bordered button * {
  color: white !important;
  font-size: 14px !important;
  line-height: initial !important;
}

button:disabled {
  opacity: 0.75 !important;
  filter: grayscale(1);
  cursor: not-allowed !important;
  /* 
  background-color: gray !important;
  color: darkgrey !important;
  filter: invert(1) grayscale(1); 
  */
}

.filter-clear-all {
  background-color: white !important;
  border: 1px solid orange !important;
}

.green-block {
  background-color: #e8f9f7 !important;
}

.list-item-checkbox svg:nth-child(2) {
  fill: var(--purpleCommon) !important;
}

.filter-toolbar {
  background-color: transparent !important;
}

.match-profile-html-item {
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.failure.page {
  background-color: #ededed;
  min-height: 100vh;
}

.failure.page .error {
  color: #ededed !important;
}

.results {
  background-color: rgba(7, 11, 0, 0.8);
}

.announcement-headers,
.announcement-row {
  min-width: 900px;
}

.view.announcement-headers,
.view.announcement-row {
  min-width: 1010px;
}

.announcement-headers {
  padding: 10px 0;
  border-bottom: 1px solid #ccc;
}

.management.announcement-headers > div:nth-child(2),
.management.announcement-headers > div:nth-child(3),
.management.announcement-headers > div:nth-child(4) {
  width: 256px;
}

.management.announcement-headers > div:nth-child(1),
.management.announcement-row > div:nth-child(1) {
  width: 32px;
}

.view.announcement-headers,
.view.announcement-row {
  display: table;
  min-height: 50px;
}

.view.announcement-headers > div,
.view.announcement-row > div {
  display: table-cell;
  min-width: 256px;
}

.view.announcement-headers > div:nth-child(1),
.view.announcement-row > div:nth-child(1) {
  min-width: 150px;
}

.view.announcement-headers > div:nth-child(4),
.view.announcement-row > div:nth-child(4) {
  min-width: 500px;
}

.view.announcement-headers > div:nth-child(5),
.view.announcement-row > div:nth-child(5) {
  min-width: 50px;
}

/* SNACK BAR */
.snack-bar > * {
  width: 100vw !important;
  max-width: 100vw !important;
  text-align: center !important;
}

/* TOOLS */
.align-right.tools > * {
  margin-left: 16px;
}

.list.tools > * {
  margin: 24px 0;
}

.list.tools {
  padding: 24px;
}

.inline-blocks.tools > * {
  margin: 0px 16px 16px 0;
}

/* LIST */
.list {
  position: relative;
  padding-top: 22px;
}

.list-scroller {
  padding: 0 24px 24px;
  outline: none;
}

.list-scroller .row-card:last-child {
  margin-bottom: 2.5rem !important;
}

.list-scroller.no-tabs {
  max-height: calc(100vh - 122px) !important;
}

.sort-buttons {
  margin: auto;
  text-align: left !important;
  margin: 0 24px;
}

.sort-button {
  border-top-left-radius: 4px !important;
  border-top-right-radius: 4px !important;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  border-bottom: 3px solid var(--purpleCommon) !important;
  margin: 0 !important;
  letter-spacing: 0.87px !important;
  padding: 3px 15px 0 !important;
  text-transform: capitalize !important;
  vertical-align: bottom !important;
  font-size: 0.75rem !important;
  font-weight: 400 !important;
  color: var(--blackMedium) !important;
}

.tab-selected {
  background-color: var(--purpleCommon) !important;
  font-weight: 500 !important;
  color: var(--whiteCommon) !important;
  font-size: 0.875rem !important;
}

.table-scroll-x > div:nth-child(1) > div:nth-child(2) {
  min-width: 1024px !important;
  overflow-x: auto !important;
}

.account-toolbar {
  width: 100%;
  text-align: right;
  padding: 24px 24px 0;
}

.list-match,
.list-add-new {
  margin-left: 2px !important;
}

.list-match,
.filter-saved-searches,
.filter-saved-searches *,
.list-add-new,
.list-add-new * {
  text-align: center;
}

.list .group-header {
  margin: 24px 16px;
}

.list-floating-button {
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 1;
}

.list-floating-button * {
  text-align: center !important;
}

.card-col-header {
  margin-bottom: 10px;
}

.card-columns > * > * {
  display: block !important;
}

.card-options > * {
  display: inline-block !important;
  vertical-align: middle !important;
}

.card-col-dropdown {
  margin-top: 10px !important;
}

.card-col-button {
  margin-top: 10px !important;
}

.row-card {
  margin: auto;
}

.row-card .last-col {
  text-align: right !important;
  width: 100px;
  padding-left: 2px !important;
  padding-right: 20px !important;
}

.row-card .link-item,
.row-card .mid-col {
  padding-left: 2px !important;
  padding-right: 2px !important;
}

.row-card .first-item a,
.row-card .link-item a {
  text-decoration: none;
}

.row-card .v-align-mid > * {
  display: inline-block;
  vertical-align: middle;
}

.row-card .show-more > span {
  display: inline-block;
  vertical-align: top;
  width: 56px;
  height: 24px;
}

.row-card .show-more > i {
  display: inline-block;
  width: 24px;
  height: 24px;
  margin: 0;
}

/** ROW CARD: collapsed-view */
.row-card .collapsed-view tr {
  border-bottom-width: 0 !important;
}

.row-card .collapsed-view tr,
.row-card .collapsed-view td {
  height: 24px !important;
}

.row-card .collapsed-view td.taller {
  height: 48px !important;
}

.row-card .collapsed-view td {
  outline: none;
}

.row-card.candidate .collapsed-view td {
  white-space: normal !important;
}

.row-card.candidate .collapsed-view .tr-match-override .td-match-override {
  white-space: nowrap !important;
}

/** important to slide header content on small screens */
.row-card .collapsed-view {
  min-width: 768px;
  margin-bottom: 10px !important;
}

/** important to slide header content on small screens */
.row-card > div:nth-child(1) > div:nth-child(1) > div:nth-child(2) {
  min-width: 768px !important;
  overflow-x: auto !important;
}

.starred,
.row-card .star {
  position: relative !important;
  display: inline-block !important;
  height: 24px !important;
  width: 24px !important;
  margin: 0 !important;
}

.starred *,
.row-card .star * {
  position: absolute !important;
  height: 100% !important;
  width: 100% !important;
  top: 0 !important;
  left: 0 !important;
  margin: 0 !important;
}

/* ROW CARD: expanded-view */
.row-card .card-header {
  text-transform: uppercase;
}

.row-card .details {
  width: 100%;
}

.row-card .details span {
  display: inline-block;
  width: 20%;
  margin: 0;
}

.row-card .contents {
  margin: 16px 0 24px 48px;
  padding: 0 !important;
}

.row-card .contents pre {
  width: 100%;
  white-space: pre-wrap;
  /* Since CSS 2.1 */
  white-space: -moz-pre-wrap;
  /* Mozilla, since 1999 */
  white-space: -pre-wrap;
  /* Opera 4-6 */
  white-space: -o-pre-wrap;
  /* Opera 7 */
  word-wrap: break-word;
  /* Internet Explorer 5.5+ */
}

.card-eng-status {
  border: 1px solid #eee;
  border-radius: 2px;
  padding: 5px;
  margin-top: 5px;
}

.date-picker .picker_adornment {
  pointer-events: none;
  background-color: white;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  width: auto;
}

.date-picker input[type='date'] {
  position: relative;
  opacity: 0;
}

.date-picker > .f-small {
  min-width: 60px;
}

.date-picker:hover {
  outline: 1px solid;
  border-radius: 2px;
}

.date-picker:hover .btn-remove,
.date-picker:hover ::-webkit-calendar-picker-indicator {
  display: inline-block !important;
}

.date-picker ::-webkit-calendar-picker-indicator {
  cursor: context-menu;

  /** 
   * @hack 
   * To hide the calendar indicator, 
   * complemented with a parent hover
   * to display it.
   * /
  display: none;
  /**/

  /** 
   * @hack 
   * To open HTML date picker dialog, 
   * on click anywhere inside input.
   */
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
  /**/
}

.date-picker .btn-mdash,
.date-picker .btn-mdash * {
  background-color: transparent !important;
  min-width: 70px !important;
  width: 70px !important;
  height: 16px !important;
  line-height: 16px !important;
  padding: 0 !important;
  margin: 0 !important;
  text-align: center;
}

.dashed-all,
.dashed-all *,
.dashed {
  outline: 1px dashed #ccc !important;
}

.dashed-darker {
  outline: 1px dashed #aaa !important;
}

.dashed-orange {
  outline: 1px dashed var(--orangeCommon) !important;
}

.card-picker-date {
  width: 70px !important;
}

.card-picker-date,
.card-picker-date * {
  cursor: pointer !important;
  padding: 0 !important;
  margin: 0 !important;
  border: none !important;
  outline: none !important;
  vertical-align: top !important;
}

.card-picker-date,
.card-picker-date > div > input {
  width: 70px !important;
  height: 16px !important;
}

/* RICH TEXT BOX */
.rich-text-box {
  margin: 5px 0;
}

.rich-text-box .Dropdown__root___3ALmx {
  margin: 0;
  top: 0px;
}

.rich-text-box .Dropdown__root___3ALmx select {
  height: 30px;
  outline: none;
}

.ui-email-preview .rich-text-box .public-DraftEditor-content {
  max-height: calc(100vh - 672px);
}

.rich-text-box .public-DraftEditor-content {
  min-height: 10em;
  max-height: 30em;
}

.rich-text-box.half-height .public-DraftEditor-content {
  min-height: 10em;
  max-height: 15em;
}

.rich-text-box.disabled {
  border: 2px dotted;
}

.rich-text-box.disabled * {
  cursor: not-allowed;
}

/* FORMS */
.edit {
  width: 100vw;
  min-height: 100vh;
}

.form {
  position: relative;
}

.form-save-btn {
  position: absolute !important;
  top: 16px !important;
  right: 16px !important;
  z-index: 2;
}

.form-header > div:nth-child(n + 2) {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.form-header > div:nth-child(2) {
  margin-top: 8px;
}

.recruiter-rating > div:nth-child(3) {
  max-width: 30% !important;
}

.relation-ship > div:nth-child(4) {
  max-width: 25% !important;
}

.candidate-resume {
  width: 100%;
  height: 32px;
}

.candidate-resume > * {
  display: inline-block;
  vertical-align: middle;
}

.candidate-checkboxes > div {
  display: inline-block !important;
}

.candidate-prefer > div:nth-child(2) {
  display: flex;
}

.candidate-prefer > div:nth-child(2) > div {
  width: 20% !important;
}

.checkbox-group {
  display: flex;
  flex-wrap: wrap;
}

.checkbox-group > div {
  margin: 16px 0;
  width: 20% !important;
}

.radio-button-group {
  display: flex;
  flex-wrap: wrap;
}

.radio-button-group > div {
  margin: 16px 0;
  width: 20% !important;
}

.radio-button-group._25 > div {
  margin: 16px 0;
  width: 25% !important;
}

/** ACCOUNT */
.edit-account-list-row li {
  margin: 16px 0;
}

.edit-account-list-col {
  position: relative;
}

.edit-account-list-col .icon-button {
  position: absolute !important;
  bottom: 0;
  right: 0;
}

/** MATCH */
.match {
  width: 100vw;
  min-height: 100vh;
  background-color: #fafaff;
}

.match-left-side {
  display: inline-block;
  width: 580px;
  padding: 8px 0 24px 24px;
}

.match-right-side {
  width: calc(100% - 580px);
}

.match-profile-details {
  overflow: auto;
  max-height: calc(100vh - 320px);
  margin-top: 16px;
}

.match-profile-html-item {
  border-radius: 3px;
  padding: 4px;
}

/** SLIDER */
.slider {
  margin-top: 8px;
}

.slider-comp {
  width: calc(100% - 111px) !important;
  margin-right: 16px !important;
}

.slider-comp > div {
  margin-top: 6px !important;
}

.slider-input input {
  border-radius: 3px;
  width: 120px !important;
}

.slider-input hr {
  display: none !important;
}

.slider-input,
.slider-input * {
  padding: 0 !important;
  margin: 0 !important;
  text-align: center !important;
}

/** TAG BOX */
.tag-box {
  min-width: 364px !important;
}

/** COMMON */
.anchor,
.row-card span.anchor {
  color: var(--purpleCommon) !important;
  text-decoration: underline;
}

.centered-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.centered-content > * {
  margin: 0;
}

.display-cell {
  display: table-cell !important;
}

.cursor-default {
  cursor: default !important;
}

.anchor,
.cursor-pointer,
.pointer {
  cursor: pointer !important;
}

.help,
.cursor-help {
  cursor: help !important;
}

.cursor-move {
  cursor: move !important;
}

.flex-1 {
  flex: 1 !important;
}

.flex.nowrap {
  flex-wrap: nowrap !important;
}

.block {
  display: block !important;
}

.blocks > * {
  display: block !important;
}

.align-right,
.align-rights * {
  text-align: right !important;
  justify-content: right !important;
  justify-items: right !important;
}

.align-center {
  text-align: center !important;
  justify-content: center !important;
  justify-items: center !important;
}

.align-left {
  text-align: left !important;
  justify-content: left !important;
  justify-items: left !important;
}

.center,
.center * {
  text-align: center !important;
  justify-content: center !important;
  justify-items: center !important;
  vertical-align: middle !important;
}

.inline-blocks > * {
  display: inline-block !important;
  vertical-align: middle !important;
}

.inline-blocks {
  white-space: normal !important;
}

.space-between {
  justify-content: space-between !important;
}

.inline-blocks.no-wrap {
  white-space: nowrap !important;
}

.bold,
.bolder {
  font-weight: bold;
}

.italic {
  font-style: italic !important;
}

.lh-normal {
  line-height: normal !important;
}

.v-align-top {
  vertical-align: top !important;
}

.v-align-tops > * {
  vertical-align: top !important;
}

.v-align-mid {
  vertical-align: middle !important;
}

.v-aligns-middle > * {
  vertical-align: middle !important;
}

.v-aligns-bottom > * {
  vertical-align: bottom !important;
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.hover-bg:hover {
  background-color: rgba(0, 0, 0, 0.05) !important;
}

.hover-bg-children:hover > * {
  background-color: #d6d7d9 !important;
}

.main-padding {
  padding-top: 24px !important;
  padding-left: 24px !important;
  padding-right: 24px !important;
  padding-bottom: 24px !important;
}

.normal-padding,
.padding-10 {
  padding-top: 10px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
  padding-bottom: 10px !important;
}

.padding-top-bottom {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.no-side-paddings,
.no-side-paddings * {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.min-100vh {
  min-height: 100vh !important;
}

.min-w768px {
  min-width: 768px !important;
}

.min-w480px {
  min-width: 480px !important;
}

.none {
  display: none !important;
}

.padding-top {
  padding-top: 10px !important;
}

.padding-tops > * {
  padding-top: 10px !important;
}

.padding-left {
  padding-left: 10px !important;
}

.padding-top-left {
  padding-top: 10px !important;
  padding-left: 10px !important;
}

.padding-side {
  padding-left: 24px !important;
  padding-right: 24px !important;
}

.margin-top {
  margin-top: 10px !important;
}

.margin-tops > * {
  margin-top: 10px !important;
}

.margin-top-bottom {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.margin-right {
  margin-right: 10px !important;
}

.margin-rights > * {
  margin-right: 10px !important;
}

.m10s > * {
  margin: 10px !important;
}

.main-margin {
  margin-top: 24px !important;
  margin-left: 24px !important;
  margin-right: 24px !important;
  margin-bottom: 24px !important;
}

.no-margin,
.no-margins > * {
  margin-top: 0 !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  margin-bottom: 0 !important;
}

.normal-margin {
  margin-top: 10px !important;
  margin-left: 10px !important;
  margin-right: 10px !important;
  margin-bottom: 10px !important;
}

.opacity-1 {
  opacity: 1 !important;
}

.translucid {
  opacity: 0.5 !important;
}

.transparent {
  opacity: 0 !important;
}

.dotted {
  border: 1px dotted !important;
}

.absolute {
  position: absolute !important;
}

.absolute-bottom-right {
  position: absolute;
  bottom: 0;
  right: 0;
}

.fixed-bottom-right {
  position: fixed;
  bottom: 0;
  right: 0;
}

.fixed-bottom {
  position: fixed;
  bottom: 0;
}

.fullwidth {
  width: 100%;
}

.relative {
  position: relative !important;
}

.fixed {
  position: fixed !important;
}

.nowrap {
  white-space: nowrap !important;
}

.scroll-x {
  overflow-x: auto !important;
  overflow-y: hidden !important;
}

.scroll-y {
  overflow-y: auto !important;
  overflow-x: hidden !important;
}

.scroll-x-visible {
  overflow-x: visible !important;
}

.scroll-y-visible {
  overflow-y: visible !important;
}

.scroll {
  overflow: auto !important;
}

.toolbar {
  min-width: 320px;
}

.layout-auto {
  table-layout: auto !important;
}

.warning-text {
  display: block;
  position: relative;
}

.green-block {
  display: block;
  margin: 16px;
}

.ondev {
  opacity: 0.4;
}

.results {
  padding: 24px;
  max-height: calc(100vh - 200px);
  overflow: auto;
  border-radius: 3px;
}

.wrapperCopy {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99999;
  width: calc(100vw - 48px);
  height: calc(100vh - 48px);
  background-color: white;
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
  border-radius: 2px;
  padding: 30px;
  overflow: auto;
}

.icon16,
.icon16 * {
  margin: 0 !important;
  padding: 0 !important;
  width: 16px !important;
  height: 16px !important;
  font-size: 16px !important;
  line-height: 16px !important;
}

.icon18,
.icon18 * {
  margin: 0 !important;
  padding: 0 !important;
  width: 18px !important;
  height: 18px !important;
  font-size: 18px !important;
  line-height: 18px !important;
}

.icon24,
.icon24 * {
  margin: 0 !important;
  padding: 0 !important;
  width: 24px !important;
  height: 24px !important;
  font-size: 24px !important;
  line-height: 24px !important;
}

.icon32,
.icon32 * {
  margin: 0 !important;
  padding: 0 !important;
  width: 32px !important;
  height: 32px !important;
  font-size: 32px !important;
  line-height: 32px !important;
}

.icon48,
.icon48 * {
  margin: 0 !important;
  padding: 0 !important;
  width: 48px !important;
  height: 48px !important;
  font-size: 48px !important;
  line-height: 48px !important;
}

/* material-table className must be on the parent of the MaterialTableComponent */
/* because the HtmlTable is wrapped in a Div by material */
/* and that Div has "overflow-x" set as hidden which is need as auto */
/* to slide horizontally the content in small screens */
.material-table {
  margin-top: 8px;
}

.material-table table {
  table-layout: auto !important;
}

.material-table > div > div:nth-child(2) {
  overflow-x: auto !important;
}

.material-table td {
  white-space: normal !important;
}

.padding-top-64 {
  padding-top: 64px !important;
}

.padding-top-96 {
  padding-top: 96px !important;
}

.collection-controls {
  text-align: right;
}

.collection-info {
  text-align: left;
  padding: 0 16px;
  min-width: 130px;
  height: 32px;
  line-height: 32px;
}

.inactive {
  opacity: 0.5 !important;
}

.input-1,
.input-1 input {
  height: 40px !important;
  line-height: 40px !important;
}

.input-1 input {
  background-color: #fff !important;
  border: 1px solid #dedede !important;
  border-radius: 3px !important;
  padding-left: 10px !important;
}

.input-1 hr {
  display: none !important;
}

.item {
  background-color: #f7f7f7 !important;
  border: 0.2px solid #dedede !important;
  border-radius: 3px !important;
  margin: 10px !important;
}

.outline {
  outline: 1px solid !important;
}

.outlines > * {
  outline: 1px solid !important;
}

.width-auto {
  width: auto !important;
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}

.animated-background {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #ffffff;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background-size: 800px 104px;
  height: 96px;
  position: relative;
}

@media only screen and (max-width: 320px) {
}

@media only screen and (max-width: 375px) {
}

@media only screen and (max-width: 768px) {
  .row-card .contents {
    margin: 16px 0 24px;
  }

  .row-card .details span {
    width: 30% !important;
  }

  .filter-control-search-input {
    width: calc(100% - 4px) !important;
  }
}

@media only screen and (max-width: 960px) {
  .match-left-side {
    width: 100%;
    padding: 8px 24px 16px;
  }

  .match-right-side {
    width: 100%;
  }
}

/** HACKS */
.card-picker-date-tf > div:before {
  border-bottom: none !important;
}

.stage-col-include-reason,
.stage-col-include-reason * {
  font-size: 13px !important;
  max-width: 160px;
}

iframe.covered {
  border: none;
  width: 100%;
  height: 100%;
}

.filter-skip {
  color: lightgray !important;
  border: 1px solid lightgray !important;
  background-color: white !important;
  height: 30px !important;
  line-height: 30px !important;
  margin-right: 5px !important;
  min-width: 50px !important;
}

.filter-right-heading {
  color: white !important;
  /* border: 1px solid lightgray !important; */
  background-color: #3661b6 !important;
  height: 30px !important;
  line-height: 30px !important;
  margin-right: 5px !important;
  min-width: 50px !important;
}

.buttons-default {
  border-radius: 20px !important;
  margin-right: 5px !important;
  color: gray !important;
  font-size: 10px !important;
  height: 30px !important;
  line-height: 30px !important;
  margin-bottom: 5px !important;
}

.buttons-simple {
  border: 1px solid gray !important;
}

.buttons-valued {
  border: 1px solid green !important;
}

.buttons-not-valued {
  border: 1px solid red !important;
}

.toolbar-left {
  padding: 19px 0px 19px 48px;
  border-right: 1px solid lightgray;
}

.toolbar-right {
  padding: 19px 0px 19px 48px;
}

.heading-right {
  font-size: 12px;
  padding: 19px 48px;
}

.heading-skills {
  background-color: white;
  border-bottom: 1px solid lightgray;
}

.heading-center {
  text-align: center;
  padding: 6px;
  font-weight: 500;
}

.heading-center i {
  position: relative;
  top: 5px;
}

.heading-center span {
  margin-right: 20px;
  font-size: 14px;
}

.heading-left {
  padding: 13px 50px 9px 50px;
  float: right;
  font-size: 12px;
}

.must-have-text {
  color: green;
  text-decoration: underline;
}

.subm-write {
  padding: 20px;
  font-size: 15px;
}

p.job-list-tabs {
  font-size: 10px;
  display: flex;
  justify-content: space-between;
  margin-right: 15px;
}

p.job-list-tabs a {
  cursor: pointer;
  color: #454545 !important;
  text-decoration-color: #454545 !important;
  border-bottom: 3px solid #fff;
}

p.job-list-tabs a:hover {
  border-bottom: 3px solid #665ff5;
  cursor: pointer;
}

p.job-list-tabs a strong.job-list-tab-active {
  border-bottom: 3px solid #665ff5;
}

.list-card-section.disable {
  display: none;
}

.list-card-section.disable + div div strong {
  display: none;
}

.job-card {
  color: #454545;
}

.job-card-title {
  margin: 0px;
  font-size: 14px;
  font-weight: bold;
}

/* job requirement */
.main-req {
  padding: 20px;
}

.save-job {
  text-align: center;
}

.save-job .filter-cancel {
  color: var(--purpleCommon) !important;
  border: 1px solid var(--purpleCommon) !important;
}

.save-job .filter-save {
  color: white !important;
  background-color: var(--purpleCommon) !important;
  border: 1px solid var(--purpleCommon) !important;
}

.save-job .ml10 {
  margin-left: 10px !important;
}

.save-job .uppercase {
  text-transform: uppercase;
}

.job-font-16 {
  font-size: 16px;
}

.job-font-14 {
  font-size: 14px;
}

.job-font-12 {
  font-size: 12px;
}

.job-font-10 {
  font-size: 10px;
}

.skills-add {
  border: 2px solid var(--purpleCommon);
  border-radius: 10px;
  padding: 0px 4px;
  color: var(--purpleCommon);
  font-size: 18px;
  font-weight: 400;
  cursor: pointer;
}

.must-have-req {
  margin-bottom: 10px;
}

.outer-grid {
  font-size: 14px;
  color: lightslategrey;
}

.x-icon {
  text-align: center;
}

.pt-10 {
  padding-top: 10px;
}

.skills-remove {
  border: 1px solid lightslategray;
  border-radius: 10px;
  padding: 2px 5px;
  font-size: 10px;
  cursor: pointer;
}

.main-skills {
  border: 1px solid lightslategray;
  border-radius: 10px;
  padding: 2px 5px;
  font-size: 14px;
  cursor: pointer;
}

.outer-options {
  border: 1px solid var(--purpleCommon);
  border-radius: 4px;
  padding: 2px 5px;
  font-size: 14px;
  margin-left: 5px;
  cursor: pointer;
}

.inner-1 {
  border: 1px solid var(--purpleCommon);
  border-radius: 10px;
  font-size: 10px;
  padding: 0px 3px;
  color: var(--purpleCommon);
}

.inner-2 {
  margin-left: 4px;
  font-size: 10px;
  color: var(--purpleCommon);
}

.switch-skills {
  cursor: pointer;
  text-decoration: underline;
  font-size: 10px;
}

.job-skills-popup {
  padding: 20px;
  box-shadow: 1px 0px 5px 2px #ccc;
  margin-bottom: 30px;
}

.job-tab.active-tab {
  color: var(--purpleCommon);
}

.job-tab {
  font-size: 12px;
  color: lightslategray;
  text-decoration: underline;
  margin-bottom: 10px !important;
  cursor: pointer;
}

.job-specify {
  font-size: 14px;
  margin-bottom: 10px !important;
}

.mr-10 {
  margin-right: 10px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.filter-popup-button {
  color: #00ced1 !important;
}

.ml10 {
  margin-left: 10px !important;
}

.job-custom-label {
  font-size: 14px;
  margin-top: 16px !important;
  color: lightslategray;
}

.text-right {
  text-align: right;
}

.search-icon {
  max-width: 25px !important;
  margin-top: 16px !important;
}

.req-heading {
  color: green !important;
}

.skills-heading {
  font-weight: 500 !important;
  font-size: 18px !important;
}

.skills-sub-heading {
  color: lightslategray !important;
  margin-left: 5px !important;
}

.star-menu-item {
  display: flex !important;
  align-items: center;
}

.display-flex {
  display: flex !important;
}

.material-v4-form-control * {
  color: rgba(0, 0, 0, 0.54) !important;
  font-size: 0.8rem !important;
}

img.avatar {
  border-radius: 50%;
  height: 56px;
  width: 56px;
}

.normal-weight {
  font-weight: 500;
}

.no-wrap,
.no-wraps * {
  white-space: nowrap;
}

.wrap {
  white-space: break-spaces !important;
}

.centered-wrapper {
  width: 80% !important;
  margin: auto !important;
}

.centered-content {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.centered-content > * {
  width: auto;
  height: auto;
  margin: auto;
}

.not-found {
  padding: 16px;
  /*
    max-width: 856px;
  */
  margin: auto;
  color: gray;
  font-size: 14px;
}

.package-version {
  display: block;
  position: absolute !important;
  top: 0;
  right: 0;
  width: auto;
  height: 11px;
  color: white;
  z-index: 9999;
  line-height: 11px;
  font-size: 11px;
  font-weight: normal;
  margin: 4px 20px;
  cursor: pointer;
}

.debug {
  display: block;
  position: fixed !important;
  bottom: 0;
  left: 0;
  width: 100%;
  max-height: 50vh;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  z-index: 9999;
  line-height: 14px;
  font-size: 12px;
  font-weight: normal;
  padding: 4px;
  margin: 0;
  overflow: auto;
  cursor: pointer;
}

.debug:empty {
  padding: 0;
}

.padding-16 {
  padding: 16px !important;
}

.subheader {
  color: rgba(0, 0, 0, 0.74);
  font-weight: bold;
}

.row {
  justify-content: space-between;
  display: flex;
}

.cols-1 > * {
  display: block !important;
  width: 100%;
}

.cols-2 {
  display: flex !important;
  flex-wrap: nowrap;
}

.cols-2 > * {
  width: 50%;
}

.cols-3 {
  display: flex !important;
  flex-wrap: nowrap;
}

.cols-3 > * {
  width: 33.33%;
}

.cols-4 {
  display: flex !important;
  flex-wrap: nowrap;
}

.cols-4 > * {
  width: 25%;
}

.cols-5 {
  display: flex !important;
  flex-wrap: nowrap;
}

.cols-5 > * {
  width: 20%;
}

.cols-6 {
  display: flex !important;
  flex-wrap: nowrap;
}

.cols-6 > * {
  width: 16.66%;
}

.cols-7 {
  display: flex !important;
  flex-wrap: nowrap;
}

.cols-7 > * {
  width: 14.28%;
}

.cols-8 {
  display: flex !important;
  flex-wrap: nowrap;
}

.cols-8 > * {
  width: 12.5%;
}

@media (max-width: 375px) {
  .cols-2.flexible > *,
  .cols-3.flexible > *,
  .cols-4.flexible > *,
  .cols-5.flexible > *,
  .cols-6.flexible > *,
  .cols-7.flexible > *,
  .cols-8.flexible > * {
    width: 100%;
  }
}

@media (min-width: 375px) and (max-width: 768px) {
  .cols-2.flexible > *,
  .cols-3.flexible > *,
  .cols-4.flexible > * {
    width: 100%;
  }

  .cols-5.flexible,
  .cols-6.flexible,
  .cols-7.flexible,
  .cols-8.flexible {
    flex-wrap: unset;
  }

  .cols-5.flexible > *,
  .cols-6.flexible > *,
  .cols-7.flexible > *,
  .cols-8.flexible > * {
    min-width: 320px;
    width: 50%;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .cols-2.flexible,
  .cols-3.flexible,
  .cols-4.flexible,
  .cols-5.flexible,
  .cols-6.flexible,
  .cols-7.flexible,
  .cols-8.flexible {
    flex-wrap: unset;
  }

  .cols-2.flexible > *,
  .cols-3.flexible > *,
  .cols-4.flexible > * {
    min-width: 320px;
    width: 50%;
  }

  .cols-5.flexible > *,
  .cols-6.flexible > *,
  .cols-7.flexible > *,
  .cols-8.flexible > * {
    min-width: 320px;
    width: 25%;
  }
}

.w2 {
  width: 2vw !important;
}

.w5 {
  width: 5vw !important;
}

.w10 {
  width: 10vw !important;
}

.w20 {
  width: 20vw !important;
}

.w25 {
  width: 25vw !important;
}

.w30 {
  width: 30vw !important;
}

.w33 {
  width: 33.33vw !important;
}

.w40 {
  width: 40vw !important;
}

.w50 {
  width: 50vw !important;
}

.w60 {
  width: 60vw !important;
}

.w66 {
  width: 66.66vw !important;
}

.w70 {
  width: 70vw !important;
}

.w75 {
  width: 75vw !important;
}

.w80 {
  width: 80vw !important;
}

.w90 {
  width: 90vw !important;
}

.w100 {
  width: 100vw !important;
}

.w5p {
  width: 5% !important;
}

.w10p {
  width: 10% !important;
}

.w20p {
  width: 20% !important;
}

.w25p {
  width: 20% !important;
}

.w30p {
  width: 30% !important;
}

.w40p {
  width: 40% !important;
}

.w50p {
  width: 50% !important;
}

.w60p {
  width: 60% !important;
}

.w70p {
  width: 70% !important;
}

.w75p {
  width: 75% !important;
}

.w80p {
  width: 80% !important;
}

.w90p {
  width: 90% !important;
}

.w100p {
  width: 100% !important;
}

.col1 {
  width: 8.33% !important;
}

.col2 {
  width: 16.66% !important;
}

.col3 {
  width: 25% !important;
}

.col4 {
  width: 33.33% !important;
}

.col5 {
  width: 41.66% !important;
}

.col6 {
  width: 50% !important;
}

.col7 {
  width: 58.33% !important;
}

.col8 {
  width: 66.66% !important;
}

.col9 {
  width: 75% !important;
}

.col10 {
  width: 83.33% !important;
}

.col12 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.W-0 {
  width: 0 !important;
}

.w-min-80 {
  min-width: 80px !important;
}

.w240 {
  width: 240px !important;
}

.w320 {
  width: 320px !important;
}

.w400 {
  width: 400px !important;
}

.maxW80p {
  max-width: 80% !important;
}

.ui-g {
  padding: 16px 0;
}

.ui-g-2 {
  display: inline-block;
  width: calc(16.66% - 32px);
  padding: 0 16px;
}

.ui-g-4 {
  display: inline-block;
  width: calc(33.33% - 32px);
  padding: 0 16px;
}

.ui-g-6 {
  display: inline-block;
  width: calc(50% - 32px);
  padding: 0 16px;
}

.ui-g-8 {
  display: inline-block;
  width: calc(66.66% - 32px);
  padding: 0 16px;
}

.ui-g-10 {
  display: inline-block;
  width: calc(83.33% - 32px);
  padding: 0 16px;
}

.ui-g-12 {
  display: block;
  width: 100%;
  padding: 0 16px;
}

/** MINIMUM DISPLAY */
@media only screen and (max-width: 320px) {
  .ui-xs-min {
    display: none !important;
  }
}

@media only screen and (max-width: 375px) {
  .ui-s-min {
    display: none !important;
  }
}

@media only screen and (max-width: 768px) {
  .ui-m-min {
    display: none !important;
  }
}

@media only screen and (max-width: 1024px) {
  .ui-l-min {
    display: none !important;
  }
}

@media only screen and (max-width: 1200px) {
  .ui-xl-min {
    display: none !important;
  }
}

/** MAXIMUM DISPLAY */
@media only screen and (min-width: 320px) {
  .ui-xs-max {
    display: none !important;
  }
}

@media only screen and (min-width: 375px) {
  .ui-s-max {
    display: none !important;
  }
}

@media only screen and (min-width: 768px) {
  .ui-m-max {
    display: none !important;
  }
}

@media only screen and (min-width: 1024px) {
  .ui-l-max {
    display: none !important;
  }
}

@media only screen and (min-width: 1200px) {
  .ui-xl-max {
    display: none !important;
  }
}

.expanded-view table td {
  padding-left: 8px !important;
  padding-right: 8px !important;
}

.µ-fieldset-header,
.µ-public-job-desc {
  display: flex;
  place-content: flex-start center;
  margin-top: 0.5rem;
}

.µ-fieldset-header {
  place-content: flex-start;
}

.µ-fieldset-header > * {
  align-self: center;
}

.µ-fieldset-header label,
.µ-public-job-desc label {
  align-self: center;
}

.µ-public-job-desc input {
  display: inline-block;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  padding: 0.4rem 1rem;
  outline: 0 !important;
}

.µ-snack-bar {
  background-color: rgba(200, 166, 0, 0.9);
  top: 4.5rem;
  bottom: auto !important;
  border-radius: 4px;
  overflow: auto;
}

.µ-snack-bar > * {
  background-color: transparent !important;
  height: auto !important;
  width: auto !important;
  min-width: 25vw !important;
  max-width: 100vw !important;
  max-height: calc(100vh - 5rem) !important;
  text-align: center !important;
}

.µ-snack-bar div {
  line-height: 2.5rem !important;
}

.µ-snack-bar span {
  font-size: 1.5rem !important;
  font-weight: bold !important;
  color: rgba(255, 255, 255, 0.9) !important;
}

.µ-textfield {
  background-color: transparent !important;
  border: 0;
  border-bottom: 1px solid gray !important;
  outline: 0 !important;
  padding: 0.5rem 0;
}

.µ-textfield:hover {
  border-bottom: 1px solid black !important;
}

.µ-textfield:focus {
  border-bottom: 1px solid var(--purpleCommon) !important;
}

.border {
  border: 1px solid !important;
}

.border-red {
  border: 1px solid red !important;
}

.border-green {
  border: 1px solid green !important;
}

.border-gray {
  border: 1px solid gray !important;
}

.borders > * {
  border: 1px solid !important;
}

.border-1 {
  border: 1px solid #dedede !important;
  border-radius: 3px;
}

.border-bottom {
  border-bottom: var(--outlined) !important;
}

.border-top {
  border-top: var(--outlined) !important;
}

.border-left {
  border-left: var(--outlined) !important;
}

.border-right {
  border-right: var(--outlined) !important;
}

.border {
  border: var(--outlined) !important;
}

.border-none {
  border: none !important;
}

.border-top-none {
  border-top: none !important;
}

.border-bottom-none {
  border-bottom: none !important;
}

.border-left-none {
  border-left: none !important;
}

.border-right-none {
  border-right: none !important;
}

.border-none-all,
.border-none-all * {
  border: none !important;
}

.flex,
.d-flex {
  display: flex !important;
}

.inline-flex,
.d-inline-flex {
  display: inline-flex !important;
}

.d-block {
  display: block;
}

.inline-block,
.d-inline-block {
  display: inline-block;
}

.flex-row {
  display: flex !important;
  flex-direction: row !important;
}

.flex-col,
.flex-column {
  display: flex !important;
  flex-direction: column !important;
}

.flex-column > * {
  width: 100%;
  height: auto;
}

.flex.wrap,
.d-flex.wrap,
.flex-wrap {
  display: flex !important;
  flex-direction: row !important;
  flex-wrap: wrap !important;
}

.flex-no-wrap {
  display: flex !important;
  flex-wrap: nowrap !important;
}

.flex-align-left-center {
  display: flex !important;
  justify-content: flex-start !important;
  align-items: center !important;
}

.flex-align-left-top {
  display: flex !important;
  justify-content: flex-start !important;
  align-items: flex-start !important;
}

.flex-align-left-bottom {
  display: flex !important;
  justify-content: flex-start !important;
  align-items: flex-end !important;
}

.flex-align-right-top {
  display: flex !important;
  justify-content: flex-end !important;
  align-items: flex-start !important;
}

.flex-align-right-center {
  display: flex !important;
  justify-content: flex-end !important;
  align-items: center !important;
}

.flex-align-right-bottom {
  display: flex !important;
  justify-content: flex-end !important;
  align-items: flex-end !important;
}

.flex-align-center {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.flex-align-center-top {
  display: flex !important;
  justify-content: center !important;
  align-items: flex-start !important;
}

.flex-align-center-bottom {
  display: flex !important;
  justify-content: center !important;
  align-items: flex-end !important;
}

.align-center {
  align-items: center;
}

.s-align-center {
  align-self: center !important;
}

.t-align-right {
  text-align: right !important;
}

.t-align-left {
  text-align: left !important;
}

.justify-start {
  justify-items: flex-start;
}

.f-12 {
  font-size: 12px !important;
}

.w-auto {
  width: auto !important;
}

.last-b-0 > tbody > tr:last-child > td {
  border-bottom: 0 !important;
}

.m-auto {
  margin: auto !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.m-4 {
  margin: 2rem !important;
}

.m-3 {
  margin: 1.5rem !important;
}

.m-2 {
  margin: 1rem !important;
}

.m-1 {
  margin: 0.5rem !important;
}

.m-05 {
  margin: 0.25rem !important;
}

.m-0-all *,
.m-0-all,
.m-0 {
  margin: 0 !important;
}

.ml-auto {
  margin-left: auto !important;
}

.ml-0 {
  margin-left: 0rem !important;
}

.ml-05 {
  margin-left: 0.25rem !important;
}

.ml-1 {
  margin-left: 0.5rem !important;
}

.ml-2 {
  margin-left: 1rem !important;
}

.ml-4 {
  margin-left: 2rem !important;
}

.ml-6 {
  margin-left: 3rem !important;
}

.ml-7 {
  margin-left: 3.5rem !important;
}

.ml-8 {
  margin-left: 4rem !important;
}

.ml-10 {
  margin-left: 5rem !important;
}

.mr-auto {
  margin-right: auto !important;
}

.mr-0 {
  margin-right: 0rem !important;
}

.mr-05 {
  margin-right: 0.25rem !important;
}

.mr-1 {
  margin-right: 0.5rem !important;
}

.mr-2 {
  margin-right: 1rem !important;
}

.mr-3 {
  margin-right: 1.5rem !important;
}

.mr-4 {
  margin-right: 2rem !important;
}

.mr-5 {
  margin-right: 2.5rem !important;
}

.mr-6 {
  margin-right: 3rem !important;
}

.mr-8 {
  margin-right: 4rem !important;
}

.mb-05 {
  margin-bottom: 0.25rem !important;
}

.mb-1 {
  margin-bottom: 0.5rem !important;
}

.mb-2 {
  margin-bottom: 1rem !important;
}

.mb-3 {
  margin-bottom: 1.5rem !important;
}

.mb-4 {
  margin-bottom: 2rem !important;
}

.mt-0 {
  margin-top: 0rem !important;
}

.mt-05 {
  margin-top: 0.25rem !important;
}

.mt-1 {
  margin-top: 0.5rem !important;
}

.mt-2 {
  margin-top: 1rem !important;
}

.mt-3 {
  margin-top: 1.5rem !important;
}

.mt-4 {
  margin-top: 2rem !important;
}

.mx-6 {
  margin-left: 6rem !important;
  margin-right: 6rem !important;
}

.mx-5 {
  margin-left: 2.5rem !important;
  margin-right: 2.5rem !important;
}

.mx-4 {
  margin-left: 2rem !important;
  margin-right: 2rem !important;
}

.mx-3 {
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important;
}

.mx-2 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

.mx-1 {
  margin-left: 0.5rem !important;
  margin-right: 0.5rem !important;
}

.mx-05 {
  margin-left: 0.25rem !important;
  margin-right: 0.25rem !important;
}

.mx-0 {
  margin-left: 0rem !important;
  margin-right: 0rem !important;
}

.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.my-05 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-1 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-2 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-3 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.overflow-unset {
  overflow: unset !important;
}

.overflow-auto {
  overflow: auto !important;
}

.p-8 {
  padding: 4rem !important;
}

.p-4 {
  padding: 2rem !important;
}

.p-3 {
  padding: 1.5rem !important;
}

.p-2 {
  padding: 1rem !important;
}

.p-1 {
  padding: 0.5rem !important;
}

.p-05 {
  padding: 0.25rem !important;
}

.p-0-all,
.p-0-all *,
.p-0 {
  padding: 0rem !important;
}

.px-0 {
  padding-left: 0rem !important;
  padding-right: 0rem !important;
}

.px-1 {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}

.px-2 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.px-3 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.px-4 {
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}

.px-05 {
  padding-left: 0.25rem !important;
  padding-right: 0.25rem !important;
}

.px-8 {
  padding-left: 4rem !important;
  padding-right: 4rem !important;
}

.py-0 {
  padding-top: 0rem !important;
  padding-bottom: 0rem !important;
}

.py-05 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-1 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-2 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-3 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-4 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

.pb-3 {
  padding-bottom: 1.5rem !important;
}

.pb-2 {
  padding-bottom: 1rem !important;
}

.pb-1 {
  padding-bottom: 0.5rem !important;
}

.pb-05 {
  padding-bottom: 0.25rem !important;
}

.pr-0 {
  padding-right: 0rem !important;
}

.pr-05 {
  padding-right: 0.25rem !important;
}

.pr-1 {
  padding-right: 0.5rem !important;
}

.pr-2 {
  padding-right: 1rem !important;
}

.pr-3 {
  padding-right: 1.5rem !important;
}

.pb-2 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 2rem !important;
}

.pt-05 {
  padding-top: 0.25rem !important;
}

.pt-1 {
  padding-top: 0.5rem !important;
}

.pt-2 {
  padding-top: 1rem !important;
}

.pt-3 {
  padding-top: 1.5rem !important;
}

.pt-4 {
  padding-top: 2rem !important;
}

.pl-0 {
  padding-left: 0rem !important;
}

.pl-1 {
  padding-left: 0.5rem !important;
}

.pl-2 {
  padding-left: 1rem !important;
}

.pl-4 {
  padding-left: 2rem !important;
}

.s-16 {
  width: 16px !important;
  height: 16px !important;
}

.rounded {
  border-radius: 0.5em !important;
}

.rounded-sm {
  border-radius: 0.25em !important;
}

.circled {
  border-radius: 50% !important;
}

.outlined {
  border: var(--outlined) !important;
}

.w-col-1 {
  width: calc(20% - 0.5rem) !important;
  min-width: calc(160px - 0.5rem) !important;
}

@media only screen and (min-width: 680px) {
  .w-20-md {
    width: 20% !important;
  }
}

.w-20 {
  width: 20% !important;
}

.w-22 {
  width: 22% !important;
}

.w-33 {
  width: 33% !important;
}

.w-40 {
  width: 40% !important;
}

.w-col-2 {
  width: calc(40% - 0.5rem) !important;
  min-width: calc(320px - 0.5rem) !important;
}

.w-44 {
  width: 44% !important;
}

.w-50 {
  width: 50% !important;
}

.w-60 {
  width: 60% !important;
}

.w-80 {
  width: 80% !important;
}

.w-90 {
  width: 90% !important;
}

.w-100 {
  width: 100% !important;
}

.w-unset {
  width: unset !important;
}

.h-100 {
  height: 100% !important;
}

.h-0 {
  height: 0px !important;
}

.vh-100 {
  height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.h-md {
  min-height: calc(320px - 1rem);
  height: 996px !important;
  max-height: calc(100vh - 2rem) !important;
}

.w-sm {
  min-width: calc(320px - 1rem);
  width: 768px !important;
  max-width: calc(100vw - 2rem) !important;
}

.w-md {
  min-width: calc(320px - 1rem);
  width: 996px !important;
  max-width: calc(100vw - 2rem) !important;
}

.max-w-md {
  max-width: 996px !important;
}

.max-w-256 {
  max-width: 256px !important;
}

.min-w {
  min-width: calc(320px - 1rem);
}

.min-w-60 {
  min-width: 60px !important;
}

.min-w-80 {
  min-width: 80px !important;
}

.min-w-100 {
  min-width: 100px !important;
}

.min-w-120 {
  min-width: 120px !important;
}

.min-w-160 {
  min-width: 160px !important;
}

.min-w-180 {
  min-width: 180px !important;
}

.min-w-240 {
  min-width: 240px !important;
}

.min-w-320 {
  min-width: 320px !important;
}

.min-w-360 {
  min-width: 360px !important;
}

.min-w320px {
  min-width: 320px !important;
}

.custom-dialog-body {
  max-height: calc(100vh - 260px) !important;
}

.br-1 {
  border-right: 1px solid #ddd !important;
}

.bl-1 {
  border-left: 1px solid #ddd !important;
}

.sticky-top {
  position: sticky !important;
  top: 0 !important;
  z-index: 1;
}

.sticky-bottom {
  position: sticky !important;
  bottom: 0 !important;
  z-index: 1;
  border-bottom: var(--outlined) !important;
}

.fixed-bottom {
  position: fixed !important;
  bottom: 0 !important;
  z-index: 1;
  border-top: var(--outlined) !important;
}

.sticky-start {
  position: sticky !important;
  left: 0 !important;
  z-index: 1;
  border-right: var(--outlined) !important;
}

.sticky-end {
  position: sticky !important;
  right: 0 !important;
  z-index: 1;
  border-left: var(--outlined) !important;
}

.tanstack-resize .th {
  position: sticky !important;
  overflow: hidden;
}

.tanstack-resize .th .resizer {
  display: inline-block;
  cursor: col-resize;
  background-color: var(--whiteLight) !important;
  min-width: 2px !important;
  height: 100%;
  position: absolute;
  right: 0px;
  top: 0;
  transform: translateX(50%);
  z-index: 1;
  touch-action: none;
  user-select: none;
}

.tanstack-resize .th .resizer:hover {
  min-width: 4px !important;
  background-color: var(--whiteDark) !important;
  right: 2px;
}

.tanstack-resize .th .resizer.isResizing {
  background-color: var(--whiteCommon) !important;
  min-width: 6px !important;
  right: 4px;
}

.lowercase,
.text-lowercase {
  text-transform: lowercase !important;
}

.uppercase,
.text-uppercase {
  text-transform: uppercase !important;
}

.capitalize,
.text-capitalize {
  text-transform: capitalize !important;
}

.text-truncate {
  /** to works it is needed define the width in your element */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-mono {
  font-family: 'Roboto Mono', monospace !important;
}

.line-clamp-2 {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  display: -webkit-box;
  overflow: hidden;
}

.line-clamp-3 {
  -webkit-line-clamp: 3;
  line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.line-clamp-no-hover,
.line-clamp {
  -webkit-line-clamp: 3;
  line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.line-clamp:hover {
  display: block;
  overflow: initial;
}

.overflow-hidden {
  overflow: hidden !important;
}

.opacity-90 {
  opacity: 0.9 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.decoration-none {
  text-decoration: none !important;
}

.mc-input-number,
.mc-input-number * {
  text-align: center !important;
}

.Modal {
  height: 252px;
  width: 400px;
  margin: 20px;
  text-align: center;
}
.Modal-block {
  height: 48px;
  line-height: 48px;
}
.Modal-block > * {
  display: inline-block;
  vertical-align: bottom;
}
.Modal-block-block > * {
  display: block;
}
.ModalSchedule-TimePicker {
  border-bottom-color: 1px solid #715eff !important;
}
.ModalReminder-date {
  padding: 8px 24px;
}

.d-none {
  display: none !important;
}

/**
* DialogFilter
*/

#crisp-chatbox {
  z-index: 1000;
}

div[role='tooltip'] {
  z-index: 999999;
}

div[aria-labelledby='app-alert-dialog-title'] {
  border-radius: 1rem !important;
}

div.inside-ul-m-0 ul {
  margin: 0.5rem 0 !important;
  padding-inline-start: 2rem !important;
  overflow-wrap: break-word;
}

div.inside-ul-m-0 ul ul {
  padding-inline-start: 1rem !important;
}

.disabled {
  cursor: not-allowed !important;
  filter: grayscale(100%) !important;
  opacity: 0.6 !important;
}

@media (max-width: 1600px) {
  .hidden-on-small-screen {
    display: none !important;
  }
}
