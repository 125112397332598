/* HOVER */
.styled.ant-input-number:hover,
.styled .ant-input:hover,
.styled .ant-picker:hover,
.styled .ant-select:hover .ant-select-selector {
  border-color: var(--blackCommon) !important;
}

/* FOCUSED */
.styled.ant-input-number-focused,
.styled.ant-input-number-focused:hover,
.styled .ant-select-focused .ant-select-selector,
.styled .ant-select-focused:hover .ant-select-selector,
.styled .ant-input-affix-wrapper-focused,
.styled .ant-input-affix-wrapper:focus,
.styled .ant-picker-focused,
.styled .ant-picker:focus,
.styled .ant-input-focused,
.styled .ant-input:focus {
  border-color: var(--purpleCommon) !important;
}

/* DISABLED */
.styled .ant-select-disabled .ant-select-selector:hover,
.styled .ant-select-disabled .ant-select-selector {
  border: var(--outlined) !important;
}

/* NORMALIZING */
.styled .ant-input,
.styled .ant-select-selector {
  background-color: var(--whiteCommon) !important;
  border-radius: 5px !important;
  width: 100%;
}

/* NORMALIZING */
.styled.ant-input-number,
.styled .ant-input,
.styled .ant-input-number-input,
.styled .ant-select-selector {
  min-height: 36px !important;
}

/* NORMALIZING */
.styled .ant-switch-checked {
  background-color: var(--purpleCommon);
  color: var(--whiteCommon);
}

/* CUSTOMIZATION */
.styled .ant-popover-inner-content {
  padding: 0px;
}

/* CUSTOM RULE */
.styled .ant-select-tree-list-holder {
  overflow-y: scroll !important;
}

/* CUSTOM RULE */
.flat-styled *,
.flat-styled:hover * {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}
