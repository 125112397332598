:root {
  --placeholderColor: var(--blackCommon);
  --placeholderSpacing: 0.075rem;
  --placeholderOpacity: 0.5;
}

/* Styles for the ::placeholder pseudo-element */
.placeholder,
::placeholder {
  color: var(--placeholderColor) !important;
  font-weight: normal !important;
  font-style: italic !important;
  font-size: small !important;
  letter-spacing: var(--placeholderSpacing) !important;
  opacity: var(--placeholderOpacity) !important;
}

/* Compatibility styles for Internet Explorer */
::-ms-input-placeholder,
:-ms-input-placeholder {
  color: var(--placeholderColor) !important;
  font-weight: normal !important;
  font-style: italic !important;
  font-size: small !important;
  letter-spacing: var(--placeholderSpacing) !important;
  opacity: var(--placeholderOpacity) !important;
}

/* Specific styles for .ant-select and .filter-control placeholders */
.ant-select-selection-placeholder,
.filter-control-placeholder {
  color: var(--placeholderColor) !important;
  font-weight: normal !important;
  font-style: italic !important;
  font-size: small !important;
  letter-spacing: var(--placeholderSpacing) !important;
  opacity: var(--placeholderOpacity) !important;
}
