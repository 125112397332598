.warning-text,
.warning-chip {
  border-color: orange !important;
}

.filter-control-search-chip {
  background-color: #b9c3ff !important;
}

.filter-control-search-chip span {
  font-weight: 700 !important;
  color: var(--blackMedium) !important;
  margin-right: 0.25rem;
}

.filter-control-search-chip svg {
  fill: var(--blackMedium) !important;
}

.styled-chip {
  position: relative !important;
  max-height: 1.275rem !important;
  line-height: 1rem !important;
  background-color: var(--purpleLight) !important;
  margin-left: 0rem !important;
  margin-right: 0.125rem !important;
}

.styled-chip > span {
  font-weight: 700 !important;
  color: var(--blackMedium) !important;
  margin-left: 0.75rem !important;
  margin-right: 0rem !important;
}

.styled-chip svg {
  fill: var(--blackMedium) !important;
  position: absolute !important;
  left: 0.25rem !important;
  margin: 0rem !important;
}

.announcement-chip {
  display: block;
  width: 200px;
  height: 26px;
  padding: 2px;
  border-radius: 15px;
  overflow: hidden;
}

.announcement-chip > * {
  margin: 4px;
  display: inline-block;
  vertical-align: middle;
  font-size: 13px;
}

.announcement-chip.green {
  background-color: #d4ead4;
}

.announcement-chip.yellow {
  background-color: #ffeed4;
}

.announcement-chip.red {
  background-color: #ffc6cc;
}

.warning-chip {
  float: left;
  margin: 0px 10px !important;
  padding: 0px 5px !important;
  background: transparent !important;
  border: 1px solid !important;
}

.slim-chip {
  height: auto !important;
  padding: 0px 0px !important;
  max-width: 360px;
}

.slim-chip span {
  padding-left: 3px;
  padding-right: 3px;
  font-weight: 400;
}

.slim-chip svg {
  padding-left: 5px;
  padding-right: 0px;
}

.chip-button {
  border: 1px solid var(--cyanCommon) !important;
  border-radius: 2px !important;
  color: #ffffff !important;
}

.chips .chip,
.Tags .chip {
  display: inline-flex !important;
}

.chips .chip path,
.Tags .chip path {
  fill: var(--whiteCommon) !important;
}

.DialogFilter .chip {
  display: inline-flex;
}

.DialogFilter .chip path {
  fill: #ffffff !important;
}


