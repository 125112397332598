.sticky-note {
  position: fixed;
  top: 0;
  right: 0;
  background: white;
  border: 1px solid lightgray;
  border-radius: 5px;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  z-index: 99999;
}

.sticky_note_title {
  cursor: move;
}

.sticky-note-minimized-enter {
  transform: translateX(-100%);
}

.sticky-note-minimized-enter-active {
  transform: translateX(0);
  transition: transform 300ms;
}

.sticky-note-minimized-exit {
  transform: translateX(0);
}

.sticky-note-minimized-exit-active {
  transform: translateX(-100%);
}