:root {
  /* Main background color */
  --bgMainCommon: #f1f1f7;
  --bgMainLighter: #eaeaec;
  --bgMainLight: #e2e2e2;
  --bgMainMedium: #dbdbd8;
  --bgMainDark: #d4d4cd;
  --bgMainDarker: #ccc4c3;

  /* White */
  --whiteCommon: #ffffff;
  --whiteLighter: #f9f9fb;
  --whiteLight: #f3f3f7;
  --whiteMedium: #ededf3;
  --whiteDark: #e7e7ef;
  --whiteDarker: #e1e1eb;

  /* Gray */
  --grayCommon: #797981;
  --grayLighter: #6e6e75;
  --grayLight: #636369;
  --grayMedium: #58585d;
  --grayDark: #4d4d51;
  --grayDarker: #424246;

  /* Black */
  --blackCommon: #000000;
  --blackLighter: rgba(1, 1, 7, 0.1);
  --blackLight: rgba(1, 1, 7, 0.3);
  --blackMedium: rgba(1, 1, 7, 0.5);
  --blackDark: rgba(1, 1, 7, 0.7);
  --blackDarker: rgba(1, 1, 7, 0.9);

  /* Purple */
  --purplePrime: #5776f8; /* primary color original 🤔 */
  --purpleCommon: #536dfe;
  --purpleLighter: rgba(77, 111, 255, 0.1);
  --purpleLighterSolid: #edf1ff;
  --purpleLight: rgba(77, 111, 255, 0.3);
  --purpleMedium: rgba(77, 111, 255, 0.5);
  --purpleDark: rgba(77, 111, 255, 0.7);
  --purpleDarker: rgba(77, 111, 255, 0.9);

  /* Cyan */
  --cyanPrime: #00bfa5; /* secondary color original 🤔 */
  --cyanCommon: #009688;
  --cyanLighter: rgba(0, 111, 100, 0.1);
  --cyanLight: rgba(0, 111, 100, 0.3);
  --cyanMedium: rgba(0, 111, 100, 0.5);
  --cyanDark: rgba(0, 111, 100, 0.7);
  --cyanDarker: rgba(0, 111, 100, 0.9);

  /* Red */
  --redCommon: #ff1f00;
  --redLighter: rgba(255, 31, 0, 0.1);
  --redLight: rgba(255, 31, 0, 0.3);
  --redMedium: rgba(255, 31, 0, 0.5);
  --redDark: rgba(255, 31, 0, 0.7);
  --redDarker: rgba(255, 31, 0, 0.9);

  /* Orange */
  --orangeCommon: #ff8c00;
  --orangeLighter: rgba(255, 140, 0, 0.1);
  --orangeLight: rgba(255, 140, 0, 0.3);
  --orangeMedium: rgba(255, 140, 0, 0.5);
  --orangeDark: rgba(255, 140, 0, 0.7);
  --orangeDarker: rgba(255, 140, 0, 0.9);

  /* Yellow */
  --yellowCommon: #ffff00;
  --yellowLighter: rgba(255, 255, 0, 0.1);
  --yellowLight: rgba(255, 255, 0, 0.3);
  --yellowMedium: rgba(255, 255, 0, 0.5);
  --yellowDark: rgba(255, 255, 0, 0.7);
  --yellowDarker: rgba(255, 255, 0, 0.9);

  /* Green */
  --greenCommon: #00ff7f;
  --greenLighter: rgba(0, 255, 127, 0.1);
  --greenLight: rgba(0, 255, 127, 0.3);
  --greenMedium: rgba(0, 255, 127, 0.5);
  --greenDark: rgba(0, 255, 127, 0.7);
  --greenDarker: rgba(0, 255, 127, 0.9);

  /* Blue */
  --blueCommon: #0000ff;
  --blueLighter: rgba(0, 0, 255, 0.1);
  --blueLight: rgba(0, 0, 255, 0.3);
  --blueMedium: rgba(0, 0, 255, 0.5);
  --blueDark: rgba(0, 0, 255, 0.7);
  --blueDarker: rgba(0, 0, 255, 0.9);
}
