.virtualized-list {
  outline: none;
  padding: 0;
  margin: 0;
}

.virtualized-list-header {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  width: 100%;
  height: 48px;
  background-color: #fff;
  border-bottom: 1px solid #e0e0e0;
  font-size: 14px;
  font-weight: 500;
  color: var(--blackMedium);
}

.virtualized-list-row {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  border-bottom: 1px solid #e0e0e0;
  font-size: 13px;
  padding: 0;
}

.virtualized-list-row-content {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  width: calc(100% - 48px);
  height: 100%;
}

.virtualized-list-no-row {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  color: var(--blackMedium);
}
