.calendar-container {
  /*height: 100%; TODO: uncomment when the cointainer has a defined height*/
  height: 85vh;
  padding: 12px;
}
/** * /
.text-event div {
  white-space: pre-wrap;
}
/** */
.text-event.onsite {
  background-color: #DE2A66;
}
.text-event.onsite-inactive {
  background-color: rgba(222, 42, 102, 0.5);
}
.text-event.screen {
  background-color: #6648B6;
}
.text-event.screen-inactive {
  background-color: rgba(101, 72, 182, 0.5);
}
.text-event.start {
  background-color: #265F9D;
}
.text-event.start-inactive {
  background-color: rgba(38, 96, 157, 0.5);
}

/* Override other styles */
div.rbc-day-bg + div.rbc-day-bg {
    border-left: none;
}
div.rbc-month-view div.rbc-header + div.rbc-header {
    border-left: none;
    border-bottom: none;
    font-weight: 300;
    text-align: left;
    height: 30px;
    line-height: 30px;
}
div.rbc-month-view div.rbc-header {
    border-bottom: none;
    font-weight: 300;
    text-align: left;
    height: 30px;
    line-height: 30px;
}
div.rbc-date-cell {
  text-align: left;
}
.rbc-event2 {
  background-color: transparent!important;
}