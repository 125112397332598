.filter-control-search {
  position: relative;
  margin: 8px 0 0;
  border-radius: 3px;
  border: 0.5px solid;
}

.filter-action-bar {
  position: absolute !important;
  bottom: 4px !important;
  right: 4px !important;
}

.filter-save-search {
  width: 120px;
  margin-right: 2px !important;
}

.filter-save-search,
.filter-save-search * {
  height: 30px !important;
  line-height: 30px !important;
  text-align: center;
}

.filter-clear-all {
  width: 120px;
}

.filter-clear-all,
.filter-clear-all * {
  height: 30px !important;
  line-height: 30px !important;
  text-align: center;
}

.filter-form > * {
  display: inline-block;
  vertical-align: middle;
}

.filter-form > div {
  width: calc(100% - 48px) !important;
}

.filter-icon {
  margin: 0 8px;
}

.filter-control-search-input {
  width: calc(100% - 240px) !important;
  height: 36px !important;
}

.filter-control-search-input input {
  margin: 0 !important;
  padding: 0px !important;
}

.filter-control-search-input label {
  top: 22px !important;
  left: 8px !important;
}

.filter-control-search-input hr {
  bottom: 0 !important;
}

.filter-control-item {
  border: 0px !important;
  min-height: 24px !important;
  border-right: 0.5px solid !important;
}

.filter-control-item > div > div {
  /** fix space between label and icon */
  padding: 0 32px 0 16px !important;
}

.filter-toolbar {
  table-layout: auto !important;
  height: auto !important;
}

.filter-toolbar td {
  padding: 0 !important;
  height: auto !important;
  /* @todo confirm if something broken 2021-07-09 µ */
  /*  align-items: center;  */
}

.filter-toolbar td:nth-child(2) {
  text-align: right !important;
}

/** FILTER CONTROL: menus */
.list-item-input {
  margin: -24px 8px 0 !important;
  width: calc(100% - 16px) !important;
}

.list-item-checkbox {
  padding: 8px 8px 8px 32px !important;
}

.list-item-checkbox div:first-child {
  height: auto !important;
  width: 16px !important;
  top: 1px !important;
  left: 2px !important;
}

.list-item-checkbox svg:nth-child(2) {
  height: 24px;
  width: 24px;
}

/* SORT CONTROLS */
.show-all {
  display: inline-block !important;
  min-width: 175px !important;
}

.show-all label {
  margin-left: -10px;
}
