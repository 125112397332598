.login-middle-form {
  background-color: white;
  width: 413px;
  margin: auto;
  padding: 25px;
  border-radius: 2px;
}

.login-submit,
.login-submit * {
  height: 56px !important;
  line-height: 56px !important;
}

.login-submit {
  margin-top: 25px;
}

.login-submit button {
  background-color: #00b89c !important;
}

.login-submit span {
  color: white !important;
  letter-spacing: 2px !important;
}

.login-footer {
  background-color: transparent;
  min-height: 80px;
  color: white;
  text-align: center;
  padding: 20px;
}

.login-footer a,
.login-footer span {
  font-size: 0.8rem;
  font-weight: 300 !important;
  vertical-align: middle;
  line-height: 32px;
  letter-spacing: 1.5px !important;
}

.footer-button {
  color: white !important;
}

.footer-button a,
.footer-button span {
  text-transform: capitalize !important;
  font-weight: 300 !important;
}
