.material-icons,
.material-icons-block {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.material-icons-block {
  color: rgba(0, 0, 0, 0.54);
}

.material-icons,
.material-icons-outlined {
  color: inherit;
}

button:hover .material-icons,
button:hover .material-icons-outlined {
  color: var(--purpleCommon) !important;
}

button:hover .material-icons.c-inherit,
button:hover .material-icons-outlined.c-inherit {
  color: inherit !important;
}

.material-icons.text-icon,
material-icons-outlined.text-icon {
  font-size: 16px;
  float: left;
  position: relative;
  margin: 16px 0 0 -16px;
}

.filter-control-item .material-icons {
  color: var(--purpleCommon) !important;
}

.filter-control-item .material-icons {
  margin: 0 !important;
}

.material-icons.arrow {
  font-size: 10px;
}

.material-icons-block {
  display: block;
  line-height: 1;
}

.bg-hover:hover .material-icons {
  color: var(--purpleCommon) !important;
}

.c-white-hover:hover .material-icons,
button:hover .material-icons.c-white {
  color: var(--whiteCommon) !important;
}

.material-icons-outlined {
  font-family: 'Material Icons Outlined' !important;
}
