.f-big,
.f-big > label,
.f-big-all *,
.results,
.col > label,
.Tags .col > label,
.green-block h4 {
  font-size: 16px;
}

.col > label,
.col > div > div > label {
  font-weight: 500 !important;
}

.col .candidate-id,
.col .candidate-id span {
  line-height: 40px;
}

/** GRAY */
.col > small,
.card-picker-date input,
.collection-controls,
.col input:disabled,
.rich-text-box.disabled,
.list .group-header,
.form-header > div:nth-child(n + 2),
.row-card,
.row-card p,
.row-card li,
.row-card td,
.row-card div,
.row-card span,
.row-card pre {
  color: var(--blackMedium);
}

/** GREEN */
.text-icon,
.Tags .col > label,
.col > label,
.col > div > label,
.cgreen,
.cgreen b,
.cgreen p,
.cgreen li,
.cgreen td,
.cgreen div,
.cgreen span,
.cgreen pre,
.cgreen i {
  color: #4f8078 !important;
}

/** DARKER */
h3,
.card-column b,
.col div > div > label,
.filter-control-item div > div,
.show-all label,
.list-item-checkbox,
.list-item-checkbox div,
.list-item-checkbox svg:nth-child(2),
.rich-text-box,
.form-header > div,
.green-block > h4,
.cdarker,
.cdarker b,
.cdarker p,
.cdarker li,
.cdarker td,
.cdarker div,
.cdarker span,
.cdarker pre,
.cdarker i .cdarker path {
  color: var(--blackDarker) !important;
  fill: var(--blackDarker) !important;
}

.col > label {
  display: block;
  margin: 12px 0 0;
}

.col label {
  color: var(--cyanDarker) !important;
  font-size: 1rem !important;
  font-weight: 500 !important;
  margin-bottom: 0.25rem;
  text-transform: lowercase;
}

.col label::first-letter {
  text-transform: uppercase;
}

.col label.t-normal,
.col label.t-normal::first-letter {
  text-transform: unset !important;
}

.col h3 {
  text-transform: capitalize !important;
  color: var(--blackMedium) !important;
  font-size: 0.8rem !important;
}

.edit-account-list-row .col {
  margin: 8px;
  min-height: 64px;
}

label.legend,
.col > div > div > .legend,
.µ-fieldset-header label,
.µ-public-job-desc label {
  display: inline-block !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  color: #4f8078 !important;
  margin-right: 2rem;
  width: 256px;
}

.col label .statement,
.statement {
  text-transform: lowercase !important;
}

.col label .statement::first-letter,
.statement::first-letter {
  text-transform: uppercase !important;
}

.col label .tt-unset,
.col label .tt-unset *,
.tt-unset,
.tt-unset * {
  text-transform: unset !important;
}

.col label .tt-none,
.col label .tt-none *,
.tt-none,
.tt-none * {
  text-transform: none !important;
}
